<template>
  <div class="mywork">
    <userheader menucurrent="0" />
    <div class="content" v-loading="loading">
      <template v-if="worklist.length > 0">
        <div class="company" v-for="item in worklist" :key="item.companyid">
          <div class="companytitle">
            <b class="prefix">{{ item.companynameprefix }}</b>
            <a target="_blank" :href="item.loginurl">
              {{ item.companyname }}
            </a>
          </div>
          <div class="tag">
            <a
              class="action_item"
              v-for="(jtem, index) in item.shortcutactionlist"
              :key="index"
              :href="jtem.actionurl"
              target="_blank"
            >
              <div class="alltit">
                <i class="el-icon-tickets"></i>
                {{ jtem.actionname }}
              </div>
            </a>
            <a
              class="action_item"
              href="javascript:;"
              v-for="atem in item.postList"
              :key="atem.postId"
              target="_blank"
            >
              <div :class="atem.dptName.length > 0 ? 'tit' : 'alltit'">
                <i class="el-icon-s-custom"></i>
                {{ atem.postName }}
              </div>
              <div class="dpt" v-if="atem.dptName.length > 0">
                {{ atem.dptName }}
              </div>
            </a>
          </div>
        </div>
      </template>
      <template v-if="worklist.length == 0">
        <div class="empty">
          <i class="icon el-icon-sunrise"></i>
          <span>暂无公司</span>
        </div>
      </template>
    </div>
    <div class="clearfix"></div>
    <userfooter />
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import { usergetcurruserworkspace } from '@/api/user';
import Cookies from 'js-cookie';
import { getCookieByName } from '@/utils/helper';

export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
  },
  data() {
    return {
      firstShow: true,
      show: false,
      height: '600',
      worklist: [],
      loading: true,
    };
  },
  methods: {
    isShow() {
      this.show = !this.show;
    },
    hideDetail() {
      this.detailShow = false;
    },
    onusergetcurruserworkspace() {
      var that = this;
      usergetcurruserworkspace({}).then((res) => {
        if (res.data.code === 1) {
          that.loading = false;
          that.worklist = res.data.result;
        } else {
          that.loading = false;
          this.$message.error('参数错误');
        }
      });
    },
  },
  mounted() {
    screenLeft;

    let iscompanyauthsuccess = getCookieByName('iscompanyauthsuccess');

    // if (iscompanyauthsuccess != '1') {
    //   this.$message({
    //     showClose: false,
    //     message: '请扫码登录企业管',
    //     type: 'warning',
    //   });
    //   setTimeout(function () {
    //     window.location.href = '/index';
    //   }, 500);
    // }
  },
  created() {
    this.onusergetcurruserworkspace();
  },
};
</script>
<style scoped>
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.mywork {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.content {
  width: 100%;
  margin-top: 20px;
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  min-height: 900px;
}
.content .empty {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 300px;
  padding-bottom: 100px;
  height: 480px;
}
.content .empty i {
  font-size: 60px;
  color: #ddd;
  width: 100%;
  display: inline-block;
}
.content .empty span {
  width: 100%;
  display: inline-block;
  font-size: 28px;
  color: #ddd;
}
.mywork .company {
  float: left;
  width: 583px;
  height: 260px;
  margin-left: 15px;
  margin-bottom: 15px;
  position: relative;
  box-shadow: 0 3px 8px 0 rgba(47, 24, 24, 0.1);
  background-color: #fff;
  transition: all 0.2s linear;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
}
.mywork .company:hover {
  transform: translate3d(0, -2px, 0);
}
.mywork .company:last-child::after {
  clear: both;
  content: '';
}
.mywork .clearfix {
  clear: both;
  margin-bottom: 50px;
}
.mywork .company .companytitle {
  background-image: url('../../assets/img/mywork-bg.png');
  border-radius: 10px 10px 0 0;
  margin-bottom: 12px;
  height: 100px;
  position: relative;
}
.mywork .company .companytitle .prefix {
  display: inline-block;
  position: absolute;
  top: 26px;
  left: 12px;
  border-radius: 50%;
  width: 31px;
  height: 31px;
  line-height: 31px;
  color: #0051a6;
  border: 2px solid #0051a6;
  background: #fff;
  text-align: center;
  font-size: 25px;
  font-family: SimHei;
}
.mywork .company .companytitle a {
  display: inline-block;
  position: absolute;
  top: 31px;
  left: 51px;
  right: 12px;
  color: #fff;
  font-weight: bold;
  font-family: SimSun;
  font-size: 22px;
}
.mywork .company .tag {
  margin: 0 12px;
  height: 150px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.mywork .company .action_item {
  display: block;
  margin: 0 10px 10px 0;
  border-radius: 3px;
  text-align: center;
  font-size: 12px;
  border: 1px solid #b3d8ff;
  background: #ecf5ff;
  color: #409eff;
  padding: 3px 8px;
  height: 30px;
  line-height: 15px;
}
.mywork .company .action_item:hover {
  border: 1px solid #409eff;
  background: #409eff;
  color: #fff;
}
.mywork .company .action_item .tit {
  font-size: 12px;
}
.mywork .company .action_item .alltit {
  font-size: 12px;
  line-height: 30px;
}
.mywork .company .action_item .dpt {
  font-size: 12px;
}
</style>
